import { useCallback, useEffect, useState } from "react"
import { DictionaryEntry, dictionaryEntryQuery } from "../../types/DictionaryEntry"
import { WordlistContent } from "../../types/wordlist/Wordlist"
import useError from "../useError"
import { PostgrestError } from "@supabase/supabase-js"
import { WordlistListEntry } from "../../components/wordlistedit/WordlistEntriesList"

function WordlistContentToListEntries(
    wordlistContent: WordlistContent[],
    dictionaryEntries: DictionaryEntry[],
    deleteItem: (value: WordlistContent) => void
): WordlistListEntry[] {
    return wordlistContent.flatMap(c => {
        const dictionaryEntry = dictionaryEntries.find(d => d.id === c.dictionary_entry_id) as DictionaryEntry
        if (!dictionaryEntry)
            return []
        return { 
            dictionaryEntry: dictionaryEntry, 
            index: c.index as number, 
            word: c.word as number,
            reading: c.reading as number,
            meaning: c.meaning as number,
            deleteItem: () => deleteItem(c) 
        }
    })
}

//TODO: look into using this hook in the Wordlist List component and using "key" prop (V1f8MOQiHRw 16:53)
export default function useWordlistListEntries(wordlistContent: WordlistContent[] | undefined, deleteItem: (value: WordlistContent) => void) {
    const [wordlistListEntries, setWordlistListEntries] = useState<WordlistListEntry[]>()
    const [dictionaryEntries, setDictionaryEntries] = useState<DictionaryEntry[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [error, setError] = useState<Error | PostgrestError>()
    const { handleError } = useError()

    const updateDictionaryEntries = useCallback(async (wordlistContent: WordlistContent[]) => {
        const missingDictionaryEntryIds = wordlistContent.filter(c => !dictionaryEntries.find(d => d.id === c.dictionary_entry_id)).map(c => c.dictionary_entry_id)
        if (missingDictionaryEntryIds.length < 1)
            return {}

        const { data: newDictionaryEntries, error } = await dictionaryEntryQuery().in('id', missingDictionaryEntryIds)
        if (error) {
            handleError(error)
            setError(error)
            setIsLoading(false)
            return { error }
        }

        setDictionaryEntries(prev => [...prev, ...newDictionaryEntries])

        return {}
    }, [dictionaryEntries, handleError])

    const updateEntriesAsync = useCallback(async (wordlistContent: WordlistContent[]) => {
        if (wordlistContent.length < 1) {
            setIsLoading(false)
            setWordlistListEntries([])
            return
        }

        const { error } = await updateDictionaryEntries(wordlistContent)
        if (error)
            return

        const updatedListEntries = WordlistContentToListEntries(wordlistContent, dictionaryEntries, deleteItem)
        setWordlistListEntries(updatedListEntries)
        setIsLoading(false)
    }, [deleteItem, dictionaryEntries, updateDictionaryEntries])

    useEffect(() => {
        if (wordlistContent) {
            updateEntriesAsync(wordlistContent)
        }
    }, [updateEntriesAsync, wordlistContent])


    return { wordlistListEntries, isLoading, error }
}
