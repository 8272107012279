import { Box, Button, Modal, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function UpgradeModal({ open, onClose }: { open: boolean, onClose: () => void }) {
    const navigate = useNavigate()

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={styles.modalBox} component="form" display="flex" flexDirection="column" rowGap="20px">
                <Typography variant="h6" component="h2">Limit reached. Become an alpha-tester!</Typography>
                <Typography>This project is currently in development and it really needs testers.
                    Help improve the project and remove the limitations by becoming an alpha-tester today!</Typography>
                <Box flex={1} display="flex" flexDirection="column">
                    <Button variant="contained" onClick={() => navigate("tester-signup")}>Sign up!</Button>
                </Box>
            </Box>
        </Modal>
    )
}

const styles = {
    modalBox: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        padding: 4,
    }
}
