import { Box } from "@mui/material"
import {
    DragDropContext,
    OnDragEndResponder
} from 'react-beautiful-dnd'
import WordlistEntriesListItem from "./WordlistEntriesListItem"
import { StrictModeDroppable } from "../StrictModeDroppable"
import { DictionaryEntry } from "../../types/DictionaryEntry"

export interface WordlistListEntry {
    dictionaryEntry: DictionaryEntry,
    index: number,
    word: number,
    reading: number,
    meaning: number,
    deleteItem: () => void
}

type WordlistEntriesListProps = {
    wordlistListEntries: WordlistListEntry[]
    onDragEnd: OnDragEndResponder
}

export default function WordlistEntriesList({ wordlistListEntries, onDragEnd }: WordlistEntriesListProps) {
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <StrictModeDroppable droppableId="droppable-list">
                {provided => (
                    <Box ref={provided.innerRef} {...provided.droppableProps}>
                        {wordlistListEntries.map(w => (
                            <WordlistEntriesListItem key={w.dictionaryEntry.id} wordlistListEntry={w} />
                        ))}
                        {provided.placeholder}
                    </Box>
                )
                }
            </StrictModeDroppable>
        </DragDropContext>
    )
}
