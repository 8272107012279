import { useNavigate } from "react-router-dom"
import { supabase } from "../../api"
import { useCallback, useContext, useState } from "react"
import useError from "../../hooks/useError"
import { SnackbarContext } from "../../contexts/SnackbarContext"
import { Wordlist } from "../../types/wordlist/Wordlist"

export default function useWordlistOptionsButtonMenu(wordlist: Wordlist, onRemove: () => void) {
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [confirmationModalIsOpen, setConfirmationModalOpen] = useState<boolean>(false)
    const { handleError } = useError()
    const { snackbarSuccess } = useContext(SnackbarContext)
    const isOpen = Boolean(anchorEl)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleClickEdit = () => {
        navigate("/wordlists/edit/" + wordlist.id)
        handleClose()
    }

    const handleClickDelete = () => {
        setConfirmationModalOpen(true)
        handleClose()
    }

    const handleDelete = useCallback(async () => {
        const { data, error } = await supabase.from("wordlist").delete().eq("id", wordlist.id).select().single()
        if (error) {
            handleError(error)
            return
        }

        navigate("/")
        snackbarSuccess(`${data.name} has been deleted.`)
        onRemove()
    }, [handleError, navigate, onRemove, snackbarSuccess, wordlist.id])


    return {
        isOpen,
        anchorEl,
        confirmationModalIsOpen,
        setConfirmationModalOpen,
        handleClick,
        handleClose,
        handleClickEdit,
        handleClickDelete,
        handleDelete
    }
}
