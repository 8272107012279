import { useState } from 'react'
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import Header from './components/Header'
import Dashboard from './screens/Dashboard'
import Study from './screens/Study'
import WordlistEdit from './screens/WordlistEdit'
import { Alert, Box, Snackbar, ThemeProvider } from '@mui/material'
import { lightTheme } from './theme'
import { SessionContext } from './contexts/SessionContext'
import useSnackbar from './hooks/useSnackbar'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorScreen from './screens/ErrorScreen'
import { SnackbarContext } from './contexts/SnackbarContext'
import SessionLoader from './components/SessionLoader'
import { Session } from '@supabase/supabase-js'
import Loadable from './components/Loadable'
import AuthDisplay from './components/AuthDisplay'
import UserAccount from './screens/UserAccount'
import TesterSignup from './screens/TesterSignup'

export default function App() {
  const [isLoadingSession, setIsLoadingSession] = useState<boolean>(true)
  const [session, setSession] = useState<Session | null>(null)
  const snackbar = useSnackbar()

  return (
    <BrowserRouter>
      <ErrorBoundary fallbackRender={ErrorScreen}>
        <ThemeProvider theme={lightTheme}>
          <SnackbarContext.Provider value={snackbar}>
            <SessionLoader setSession={setSession} setIsLoading={setIsLoadingSession} isLoadingSession={isLoadingSession} />
            <Box height="100vh" bgcolor="background.default">
              <Header user={session?.user} />
              <Loadable isLoading={isLoadingSession} >
                {session ?
                  <SessionContext.Provider value={{ session }}>
                    <Routes>
                      <Route path="/" element={<Dashboard />} />
                      <Route path="/study/:listId" element={<Study />} />
                      <Route path="/wordlists/edit/:id" element={<WordlistEdit />} />
                      <Route path="/user-account" element={<UserAccount />} />
                      <Route path="/tester-signup" element={<TesterSignup />} />
                    </Routes>
                  </SessionContext.Provider> :
                  <AuthDisplay />
                }
              </Loadable>
              <Snackbar open={snackbar.isOpen} autoHideDuration={4000} onClose={snackbar.handleClose} anchorOrigin={snackbar.origin}>
                <Alert severity={snackbar.severity} variant='filled'>{snackbar.text}</Alert>
              </Snackbar>
            </Box>
          </SnackbarContext.Provider>
        </ThemeProvider>
      </ErrorBoundary>
    </BrowserRouter >
  )
}
