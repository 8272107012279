import { useNavigate, useParams } from 'react-router-dom'
import CompletedChallenge from "../components/study/CompletedChallenge"
import { Box, Container } from "@mui/material"
import StandaloneChallenge from "../components/study/StandaloneChallenge"
import { ChallengeData, PrimitivesChallengeData, StandaloneChallengeData } from "../types/study/ChallengeData"
import PartialWordPreview from "../components/study/PartialWordPreview"
import ErrorBackdrop from "../components/study/ErrorBackdrop"
import useStudy from "../hooks/study/useStudy"
import PrimitivesChallenge from "../components/study/PrimitivesChallenge"
import { StudyContext } from "../contexts/StudyContext"
import { SessionContext } from '../contexts/SessionContext'
import { useContext } from 'react'
import { DictionaryEntry } from '../types/DictionaryEntry'
import Loadable from '../components/Loadable'
import Subheader from '../components/Subheader'

function renderMainContent(dictionaryEntry: DictionaryEntry, charactersShown: number, challenge?: ChallengeData) {
    const primitivesChallenge = challenge as PrimitivesChallengeData
    if (primitivesChallenge && primitivesChallenge.primitives) {
        return (
            <PrimitivesChallenge dictionaryEntry={dictionaryEntry} challenge={primitivesChallenge} >
                <PartialWordPreview dictionaryEntry={dictionaryEntry} charactersShown={charactersShown} />
            </PrimitivesChallenge>
        )
    }

    const standaloneChallenge = challenge as StandaloneChallengeData
    if (standaloneChallenge) {
        return (
            <StandaloneChallenge dictionaryEntry={dictionaryEntry} challenge={standaloneChallenge}>
                <PartialWordPreview dictionaryEntry={dictionaryEntry} charactersShown={charactersShown} />
            </StandaloneChallenge>
        )
    }

    return (<CompletedChallenge dictionaryEntry={dictionaryEntry} />)
}

export default function Study() {
    const params = useParams()
    const navigate = useNavigate()
    const user = useContext(SessionContext).session.user
    const { wordlist, dictionaryEntry, challenge, charactersShown, backdropOpen, onMistake, onContinue, setBackdropClosed, isLoading, error } =
        useStudy(params.listId, user.id)

    return (
        <StudyContext.Provider value={{ mistakeAction: onMistake, continueAction: onContinue }}>
            <Subheader title={`${wordlist?.name ?? ""}`} backAction={() => navigate("/")} />
            <Container>
                {backdropOpen && (<ErrorBackdrop onFinished={setBackdropClosed} />)}
                <Box margin="20px 0px">
                    <Loadable isLoading={isLoading} error={error}>
                        {dictionaryEntry && renderMainContent(dictionaryEntry, charactersShown, challenge)}
                    </Loadable>
                </Box>
            </Container>
        </StudyContext.Provider>
    )
}
